import React, { useState } from 'react';
import IconBoxStyle2 from '../../IconBox/IconBoxStyle2';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';

export default function DepartmentSectionStyle4({ sectionTitle, sectionTitleUp, data, }) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto px-4">
      <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
      <Spacing md="72" lg="50" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {data?.map((item, index) => (
          <div className="col" key={index}>
            <IconBoxStyle2
              {...item}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
