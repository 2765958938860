import { Icon } from '@iconify/react';
import { t } from 'i18next';
import React from 'react';

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget">
      <li>
        <i className="cs_accent_bg">
          <Icon icon="ep:location" />
        </i>
        {t('Jeddah_Kingdom_of_Saudi_Arabia')}
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        <button onClick={() => window.open(`tel:+966506024911`)}>
          050-602-4911
        </button>
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        <button onClick={() => window.open(`tel:920011793`)}>
          9200-11793
        </button>
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="bi:envelope" />
        </i>
        ekhdomni@gmail.com
      </li>
    </ul>
  );
}
