import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import VideoModal from '../VideoModal';
import check from '../../images/check.png'
import { useTranslation } from 'react-i18next';


export default function HeroStyle2({
  title,
  subTitle,
  bgUrl,
  imgUrl,
  videoBtnText,
  videoUrl,
  funfactList,
  btnText,
  btnUrl,
}) {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr'; // Change direction based on language
  };

  useEffect(() => {
    // Set initial direction based on the initial language
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  return (
    <section
      className="cs_hero cs_style_2 cs_bg_filed"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="container">
        <div className="cs_hero_text">
          <h1 className="cs_hero_title cs_white_color cs_fs_84">{title}</h1>
          <div className="cs_hero_text_in">
            <div className="cs_hero_btn cs_white_color">
              <Link to={btnUrl} className="cs_text_btn">
                {btnText}
              </Link>
            </div>
            <p className="cs_hero_subtitle cs_white_color">{subTitle}</p>
          </div>
        </div>
        <div className='cs_hero_patents flex justify-center items-center gap-2'>
          <div className=' flex flex-col'>
            <h1 className='text-3xl font-bold text-white'>{t('Your_life_is_Important')}</h1>
            <h1 className='text-xl font-bold text-blue-400 text-center'>{t('Take_Care_of_Your_Health')}</h1>
          </div>
          <img src={check} alt="Hero" className="" />
        </div>


      </div>
      <div className="container">
        <div className="cs_hero_bottom">
          <div className="cs_hero_bottom_left">
            <div className="cs_hero_btn_wrap">
              {/* <VideoModal
                videoUrl={videoUrl}
                videoBtnText={videoBtnText}
                variant="cs_white_color"
              /> */}
            </div>
          </div>
          <div className="cs_hero_bottom_right">
            {funfactList?.length > 0 && (
              <div className="cs_hero_funfact text-center">
                {funfactList?.map((item, index) => (
                  <div className="cs_hero_funfact_col" key={index}>
                    <h3 className="cs_white_color cs_fs_72">{item.number}</h3>
                    <p className="cs_white_color mb-0">{item.title}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
