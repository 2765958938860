import { Icon } from '@iconify/react';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>{t('Follow_Us')}</h2>
      <div className="cs_social_links">
        <Link to="https://www.snapchat.com/add/ekhdomni">
          <Icon icon="fa-brands:snapchat" />
        </Link>
        {/* 
        <Link to="/">
          <Icon icon="fa-brands:youtube" />
        </Link> 
        <Link to="/">
          <Icon icon="fa-brands:linkedin-in" />
        </Link>
        */}
        <Link to="https://twitter.com/ekhdomni">
          <Icon icon="fa-brands:twitter" />
        </Link>
        <Link to="https://www.instagram.com/ekhdomni">
          <Icon icon="fa-brands:instagram" />
        </Link>
        <Link to="https://www.tiktok.com/@ekhdofwfcpv">
          <Icon icon="fa-brands:tiktok" />
        </Link>
      </div>
    </div>
  );
}
