import React from 'react';
import parse from 'html-react-parser';
import { t } from 'i18next';

export default function TextWidget({ logoUrl, text }) {
  return (
    <div className="cs_text_widget">
      <div className='flex justify-start items-center gap-6 px-2 py-3 bg-white rounded-md  mb-10 h-20 w-20'>
        {logoUrl && <img src={logoUrl} alt="Logo" />}
        <h1 className='text-white text-4xl font-black'>{t('App_Name')}</h1>
      </div>
      {text && <p className="cs_medium">{parse(text)}</p>}
    </div>
  );
}