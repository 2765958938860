import React from 'react';
import HeroStyle2 from '../Hero/HeroStyle2';
import Section from '../Section';
import AboutSectionStyle3 from '../Section/AboutSection/AboutSectionStyle3';
import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
import TestimonialSectionStyle2 from '../Section/TestimonialSection/TestimonialSectionStyle2';
import WorkingProcess from '../Section/WorkingProcess';
import BannerSectionStyle2 from '../Section/BannerSection/BannerSectionStyle2';
// import BlogSectionStyle3 from '../Section/BlogSection/BlogSectionStyle3';
import FaqSectionStyle2 from '../Section/FaqSection/FaqSectionStyle2';
// import AppointmentSectionStyle2 from '../Section/AppointmentSection/AppointmentSectionStyle2';
// import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
import ddoc from '../../images/ddoc.png'
import { t } from 'i18next';
import cookies from "js-cookie";
const departmentData = [
  {
    title: 'Home_Nursing',
    iconUrl: '/images/home_2/home_nursing.png',
    // href: '/departments/department-details',
    services: [
      {
        imgUrl: '/images/homeNursing/homeNursing_01.jpg', serviceTitleEn: 'Therapeutic accompaniment service', serviceTitleAr: 'خدمة المرافقة العلاجية (زيارة واحدة للتمريض)',
        dots: [
          {
            "bulletPointEN": "A visit from a nurse to assess the patient's needs and implement the treatment plan.",
            "bulletPointAR": "زيارة ممرض أو ممرضة لتحديد وتقييم احتياجات المريض وتنفيذ الخطة العلاجية"
          },
          {
            "bulletPointEN": "Medication organization and reminders for necessary dates.",
            "bulletPointAR": "تنظيم الأدوية والتذكير بمواعيدها الضرورية"
          },
          {
            "bulletPointEN": "Assistance with mobility.",
            "bulletPointAR": "المساعدة في التنقل"
          },
          {
            "bulletPointEN": "Checking vital signs such as blood pressure, heart rate, temperature, etc",
            "bulletPointAR": "فحص العلامات الحيوية كالضغط وعدد نبضات القلب والحرارة وغيرها"
          }
        ]
      },
      {
        imgUrl: '/images/homeNursing/homeNursing_02.jpg', serviceTitleEn: 'Medication administration service', serviceTitleAr: 'اعطاء جميع الأدوية والمحاليل والوريدية',
        dots: [
          {
            "bulletPointEN": "Intravenous injection, which involves delivering medication directly into a patient's vein through a needle.",
            "bulletPointAR": "الحقن الوريدي وهو إعطاء الدم في الوريد ليصل الى الدم مباشرة"
          },
          {
            "bulletPointEN": "Intramuscular injection, which involves injecting medication into a patient's muscle.",
            "bulletPointAR": "حقن العضلة وهي طريقة لتوصيل العلاج عن طريق العضلة"
          },
          {
            "bulletPointEN": "Administration of various types of solutions and medications.",
            "bulletPointAR": "اعطاء المحاليل والأدوية بأنواعها المختلفة"
          }
        ],
        conditionEn: "Availability of Medical prescription .",
        conditionAr: "وجود وصفة طبية"

      },
      {
        imgUrl: '/images/homeNursing/homeNursing_03.jpeg', serviceTitleEn: 'Urinary catheter and feeding tube care', serviceTitleAr: 'رعاية القسطرة البولية وأنابيب التغذية',
        dots: [
          {
            "bulletPointEN": "Taking care of the urinary catheter, including changing, cleaning, inserting and removing the urine bag",
            "bulletPointAR": "العناية بالقسطرة البولية من التغيير والتنظيف والتركيب والإزالة لكيس البول"
          },
          {
            "bulletPointEN": "Caring for the stomach tube in terms of cleaning, changing the bandage around the tube, and monitoring signs of infection",
            "bulletPointAR": "العناية بأنبوب المعدة من حيث التنظيف وتغيير الضماد حول الأنبوب ومراقبة علامات العدوى"
          }
        ],
        conditionEn: "Medical report describing the condition",
        conditionAr: "وجود تقرير طبي يصف الحاله"
      },
      {
        imgUrl: '/images/homeNursing/homeNursing_04.jpg', serviceTitleEn: 'Follow up on diabetes and high blood pressure patients', serviceTitleAr: 'متابعة مرضى السكر والضغط بشكل دوري',
        dots: [
          {
            "bulletPointEN": "Measure blood pressure accurately with modern devices, record readings in a table, and follow treatment plan for medication.",
            "bulletPointAR": "قياس الضغط بأجهزه حديثة ودقيقة وكتابة القراءة في جدول لكي تتم متابعته دورياً واعطاء دواء الضغط حسب الخطة العلاجي"
          },
          {
            "bulletPointEN": "Monitoring blood sugar levels periodically and administering medication as per the treatment plan.",
            "bulletPointAR": "قياس السكر وكتابة القراءة في جدول لكي تتم متابعته دورياً ، واعطاء دواء السكر حسب الخطة العلاجية"
          },
          {
            "bulletPointEN": "Weighting",
            "bulletPointAR": "قياس الوزن"
          },
          {
            "bulletPointEN": "Advising to avoid certain foods and drinks that can increase the risk of the disease.",
            "bulletPointAR": "اعطاء نصائح لتجنب بعض المأكولات والمشروبات التي قد تزيد من المرض وتعرض المريض للخطر"
          },
          {
            "bulletPointEN": "Reminder to take medications at the appropriate time.",
            "bulletPointAR": "التذكير بأخذ الأدوية في وقتها المناسب"
          }
        ],
        conditionEn: "Availability of Medical prescription .",
        conditionAr: "وجود وصفة طبية"
      },
      {
        imgUrl: '/images/homeNursing/homeNursing_05.jpeg', serviceTitleEn: 'Ostomy care', serviceTitleAr: 'العناية بكيس الفغر (الكيس المتصل بالأمعاء)',
        dots: [
          {
            "bulletPointEN": "Clean and change the bag",
            "bulletPointAR": "تنظيف الكيس وتغييره فقط"
          },
          {
            "bulletPointEN": "Installing or removing the bag from the external opening on the abdomen is not included.",
            "bulletPointAR": "لا يشمل تركيب الكيس ولا إزالته من الفتحة الخارجية على البطن"
          }
        ],
        conditionEn: "Availability of Medical Report",
        conditionAr: "وجود تقرير طبي يصف الحاله"
      },
      {
        imgUrl: '/images/homeNursing/homeNursing_06.jpeg', serviceTitleEn: 'Tracheostomy care', serviceTitleAr: 'العناية بأنبوب التنفس',
        dots: [
          {
            "bulletPointEN": "Clearing any clogs in the tube.",
            "bulletPointAR": "تنظيف الأنبوب حيث أنه يتعرض للانسداد"
          },
          {
            "bulletPointEN": "Keep the area clean and free of infection.",
            "bulletPointAR": "الحفاظ على المنطقة نظيفة وخالية من العدوى"
          },
          {
            "bulletPointEN": "Ensuring the tube remains in place.",
            "bulletPointAR": "التأكد من عدم خروج الأنبوب"
          },
          {
            "bulletPointEN": "Installing or removing the tube is not included.",
            "bulletPointAR": "لا يشمل إزالة الأنبوب وتركيبه"
          }
        ],
        conditionEn: "Availability of Medical Report",
        conditionAr: "وجود تقرير طبي يصف الحاله"
      },
      {
        imgUrl: '/images/homeNursing/homeNursing_07.jpg', serviceTitleEn: 'Wound dressing', serviceTitleAr: 'تضميد الجروح',
        dots: [
          {
            "bulletPointEN": "Caring for various types of wounds, such as burns, surgical wounds, or diabetic wounds, by cleaning and bandaging them.",
            "bulletPointAR": "العناية بأنواع مختلفة من الجروح مثل الحروق والجروح الجراحية او جروح السكري فيتم تضميدها وتنظيفها"
          }
        ],
        conditionEn: "Availability of Medical Report",
        conditionAr: "وجود تقرير طبي يصف الحاله"
      },
      {
        imgUrl: '/images/homeNursing/homeNursing_08.jpg', serviceTitleEn: 'Vaccinations', serviceTitleAr: 'التطعيمات',
        dots: [
          {
            "bulletPointEN": "Basic immunizations for children, seasonal vaccines like flu vaccine , travel vaccines, and vaccines for various professions.",
            "bulletPointAR": "خدمة التطعميات الأساسية الخاصة بالأطفال، بالإضافة إلى قائمة شاملة باللقاحات الموسمية (مثل حقن الإنفلوانزا) ولقاحات السفر ، وكذلك اللقاحات الخاصة بالمهن المختلفة"
          }
        ],
        conditionEn: "",
        conditionAr: ""
      },
    ],
  },
  {
    title: "Doctor_s_Home_Care",
    iconUrl: '/images/home_2/Doctor_Home_Care.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Private doctor for half a year',
        serviceTitleAr: 'طبيب خاص لمدة نصف سنة',
        dots: [
          {
            bulletPointEN: "A monthly visit to follow you throughout the day",
            bulletPointAR: "زيارة شهرية لمتابعتك طوال اليوم",
          },
          {
            bulletPointEN: "Follow up on chronic diseases such as (diabetes, high blood pressure, etc.)",
            bulletPointAR: "متابعة الأمراض المزمنة مثل (السكري ، وارتفاع ضغط الدم وغيره)",
          },
          {
            bulletPointEN: "Responding to all your calls to check on your health and when any new health ailment appears",
            bulletPointAR: "الرد على جميع اتصالاتك للاطمئنان على صحتك وعند ظهور أي وعكة صحية جديدة",
          },
          {
            bulletPointEN: "Explanation of medical reports and laboratory test results",
            bulletPointAR: "شرح التقارير الطبية ونتائج الفحوصات المخبرية",
          },
          {
            bulletPointEN: "Giving and following up on the treatment plan",
            bulletPointAR: "اعطاء ومتابعة الخطة العلاجية",
          },
          {
            bulletPointEN: "Accompaniment to hospital appointments",
            bulletPointAR: "مرافقة لمواعيد المستشفى",
          },
          {
            bulletPointEN: "Getting vaccinated at home before traveling or for influenza season.",
            bulletPointAR: "تطعيمات في المنزل (عند السفر أو الإنفلوانزا)",
          },
          {
            bulletPointEN: "Home laboratory tests",
            bulletPointAR: "فحوصات المختبر المنزلي",
          }
        ]
      },
      {
        serviceTitleEn: 'Private doctor for one year',
        serviceTitleAr: 'طبيب خاص لمدة سنة',
        dots: [
          {
            bulletPointEN: "A monthly visit to follow you up throughout the year",
            bulletPointAR: "زيارة شهرية لمتابعتك طوال العام",
          },
          {
            bulletPointEN: "Follow up on chronic diseases such as (diabetes, high blood pressure, etc.)",
            bulletPointAR: "متابعة الأمراض المزمنة مثل (السكري ، وارتفاع ضغط الدم وغيره)",
          },
          {
            bulletPointEN: "Responding to all your calls to check on your health and when any new health ailment appears",
            bulletPointAR: "الرد على جميع اتصالاتك للاطمئنان على صحتك وعند ظهور أي وعكة صحية جديدة",
          },
          {
            bulletPointEN: "Explanation of medical reports and laboratory test results",
            bulletPointAR: "شرح التقارير الطبية ونتائج الفحوصات المخبرية",
          },
          {
            bulletPointEN: "Giving and following up on the treatment plan",
            bulletPointAR: "اعطاء ومتابعة الخطة العلاجية",
          },
          {
            bulletPointEN: "Accompaniment to hospital appointments",
            bulletPointAR: "مرافقة لمواعيد المستشفى",
          },
          {
            bulletPointEN: "Getting vaccinated at home before traveling or for influenza season.",
            bulletPointAR: "تطعيمات في المنزل (عند السفر أو الإنفلوانزا)",
          },
          {
            bulletPointEN: "Home laboratory tests",
            bulletPointAR: "فحوصات المختبر المنزلي",
          }
        ]
      },
      {
        serviceTitleEn: 'Private doctor for one day',
        serviceTitleAr: 'طبيب خاص لمدة يوم',
        dots: [
          {
            bulletPointEN: "Assessing and diagnosing the health condition, then giving and following up on the treatment planA monthly visit to follow you up throughout the year",
            bulletPointAR: "تقييم وتشخيص الحالة الصحية ، ثم إعطاء ومتابعة الخطة العلاجية",
          },
          {
            bulletPointEN: "Easy to choose the time for an appointment.",
            bulletPointAR: "سهولة اختيار الوقت والموعد",
          },
          {
            bulletPointEN: "Saving time and effort for the patient and his family by avoiding long waits in the hospital or clinic.",
            bulletPointAR: "توفير الوقت والجهد للمريض وعائلته وتجنب ساعات الانتظار في المستشفى أو العيادة",
          },
          {
            bulletPointEN: "Complete confidentiality and privacy",
            bulletPointAR: "سرية وخصوصية تامة",
          },
          {
            bulletPointEN: "The service includes all age groups",
            bulletPointAR: "تشمل الخدمة جميع الفئات العمرية",
          }
        ]
      },
      {
        serviceTitleEn: 'ECG',
        serviceTitleAr: 'تخطيط القلب',
        dots: [
          {
            bulletPointEN: "Home ECG provides you with accurate and reliable diagnosis without the need to go to the hospital",
            bulletPointAR: "تخطيط القلب المنزلي توفر لك التشخيص الدقيق والموثوق دون الحاجة للذهاب إلى المستشفى",
          },
        ]
      },
    ],
  },
  {
    title: 'Elderly_Care',
    iconUrl: '/images/home_2/Elderly_Care.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Elderly Care Services',
        serviceTitleAr: 'خدمات رعاية المسنيين',
        dots: [
          {
            bulletPointEN: "Caring for the elderly who are unable to move.",
            bulletPointAR: "رعاية المسن الغير قادر على الحركة",
          },
          {
            bulletPointEN: "Therapeutic assistance",
            bulletPointAR: "المساعدة العلاجية",
          },
          {
            bulletPointEN: "Personal care",
            bulletPointAR: "العناية الشخصية",
          },
          {
            bulletPointEN: "24-hour care, nursing assistance.",
            bulletPointAR: "الرعاية والمساعدة والتمريض على مدار 24 ساعة",
          },
          {
            bulletPointEN: "Providing medical equipment",
            bulletPointAR: "يتم توفير المساعدة والدعم لجميع المهام اليومية",
          },
          {
            bulletPointEN: "Provide assistance and support for all daily tasks",
            bulletPointAR: "مرافقة لمواعيد المستشفى",
          }
        ]
      },
    ],
  },
  {
    title: 'Home_Laboratory',
    iconUrl: '/images/home_2/Home_Laboratory.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Complete Screen Package',
        serviceTitleAr: 'باقة الفحص الشامل',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-الفحص-الشامل-.pdf`
      },
      {
        serviceTitleEn: 'Basic Screen Package',
        serviceTitleAr: 'باقة الفحص الأساسية',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-الفحص-الاساسية-.pdf`
      },
      {
        serviceTitleEn: 'Pre-marriage Screening Package',
        serviceTitleAr: 'باقة فحص ماقبل الزواج',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-ماقبل-الزواج-.pdf`
      },
      {
        serviceTitleEn: 'Diabetic screening package',
        serviceTitleAr: 'باقة مرضى السكري',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-مرضى-السكري-.pdf`
      },
      {
        serviceTitleEn: 'Skin care and hair loss screening package',
        serviceTitleAr: 'باقة العناية بالبشرة وتساقط الشعر',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-العناية-بالبشرة-وتساقط-الشعر-.pdf`
      },
      {
        serviceTitleEn: 'Pediatric’s package',
        serviceTitleAr: 'باقة الأطفال',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-الاطفال-.pdf`
      },
      {
        serviceTitleEn: 'Pregnancy follow-up package',
        serviceTitleAr: 'باقة متابعة الحمل',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-متابعة-الحمل-.pdf`
      },
      {
        serviceTitleEn: 'Breast cancer screening package',
        serviceTitleAr: 'باقة سرطان الثدي',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-سرطان-الثدي-.pdf`
      },
      {
        serviceTitleEn: 'Tumor marker screening package',
        serviceTitleAr: 'باقة دلالات الأورام',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-دلالات-الاورام-.pdf`
      },
      {
        serviceTitleEn: 'Blood clotting screening package',
        serviceTitleAr: 'باقة فحص تجلط الدم',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-تجلط-الدم-.pdf`
      },
      {
        serviceTitleEn: 'Celiac disease screening package',
        serviceTitleAr: 'باقة فحص حساسية القمح',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-حساسية-القمح-.pdf`
      },
      {
        serviceTitleEn: 'Cardiovascular risk screening package',
        serviceTitleAr: 'باقة فحص مخاطر القلب والأوعية الدموية',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-مخاطر-القلب-والاوعية-الدمويه-1.pdf`
      },
      {
        serviceTitleEn: 'Chronic fatigue and lethargy package',
        serviceTitleAr: 'باقة أسباب الضعف والكسل والخمول',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-اسباب-الضعف-والخمول-والكسل-.pdf`
      },
      {
        serviceTitleEn: 'Thyroid tests packege',
        serviceTitleAr: 'باقة الغدة الدرقية',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-الغدة-الدرقية-.pdf`
      },
      {
        serviceTitleEn: 'Osteoporosis screening package',
        serviceTitleAr: 'باقة فحص تنخر العظام',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-تنخر-العظام-.pdf`
      },
      {
        serviceTitleEn: 'Periodic check test for elder people package',
        serviceTitleAr: 'باقة الفحص الدوري لكبار السن ذكور/ وإناث',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-الفحص-الدوري-لكبار-السن-ذكور-اناث-.pdf`
      },
      {
        serviceTitleEn: 'Erectile dysfunction screening package',
        serviceTitleAr: 'باقة فحص الضعف الجنسي',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-الضعف-الجنسي-.pdf`
      },
      {
        serviceTitleEn: 'Vitamins and minerals package',
        serviceTitleAr: 'باقة الفيتامينات المعادن',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-الفيتامينات-والمعادن-.pdf`
      },
      {
        serviceTitleEn: 'Happiness screening package',
        serviceTitleAr: 'باقة فحص السعادة',
        pdfImage: `${process.env.PUBLIC_URL}/باقة-فحص-السعادة-.pdf`
      },
    ]
  },
  {
    title: 'Physiotherapy_at_Home',
    iconUrl: '/images/home_2/Physiotherapy_at_Home.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Physiotherapy at Home Services',
        serviceTitleAr: 'خدمات العلاج الطبيعي الفيزيائي',
        dots: [
          {
            bulletPointEN: "All types of paralysis, including stroke.",
            bulletPointAR: "الشلل بأنواعه شاملاً الجلطات الدماغية",
          },
          {
            bulletPointEN: "Elderly and Parkinson's disease.",
            bulletPointAR: "كبار السن وحالات الشلل الرعاش",
          },
          {
            bulletPointEN: "Multiple sclerosis and neurological diseases.",
            bulletPointAR: "حالات التصلب اللويحي وأمراض الأعصاب",
          },
          {
            bulletPointEN: "Spinal cord and disc herniation.",
            bulletPointAR: "اصابات العمود الفقري والانزلاق الغضروفي",
          },
          {
            bulletPointEN: "Post-operative fractures and injuries.",
            bulletPointAR: "ما بعد العمليات الجراحية الكسور والإصابات",
          }
        ]
      },
    ],
  },
  {
    title: 'Pregnancy_and_Postpartum',
    iconUrl: '/images/home_2/Pregnancy_Postpartum.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Pregnancy & Postpartum Care Services',
        serviceTitleAr: 'خدمات رعاية الحوامل وما بعد الولادة',
        dots: [
          {
            bulletPointEN: "Personal care",
            bulletPointAR: "الرعاية الشخصية",
          },
          {
            bulletPointEN: "Regular check-ups, medical examination, and measurement of vital signs",
            bulletPointAR: "الفحوصات المنتظمة الاختبارات الطبية وقياس العلامات الحيوية",
          },
          {
            bulletPointEN: "Reminders and medication administration (if available ) .",
            bulletPointAR: "التذكير بالأدوية وإعطائها (إن وجدت)",
          },
          {
            bulletPointEN: "Baby monitoring and proper breastfeeding .",
            bulletPointAR: "متابعة الطفل وكيفية الرضاعة الصحيحة",
          },
          {
            bulletPointEN: "Nutritional advice for mother and child.",
            bulletPointAR: "الاستشارة الغذائية للأم والطفل",
          },
          {
            bulletPointEN: "Post-caesarean care",
            bulletPointAR: "رعاية مابعد الولادة القيصرية",
          }
        ]
      },
    ],
  },
  {
    title: 'Therapeutic_Nutrition',
    iconUrl: '/images/home_2/Therapeutic_Nutrition.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Therapeutic Nutrition Services',
        serviceTitleAr: 'خدمات التغذية العلاجية',
        dots: [
          {
            bulletPointEN: "A physical assessment that focuses on nutritional status creates a nutritional plan based on the patient's condition.",
            bulletPointAR: "التقييم البدني الذي يركز على الحالة الغذائية ووضع خطة نظام غذائي بناءً على حالة المريض",
          },
          {
            bulletPointEN: "Calculate anthropometric measurements (BMI, ideal body weight and growth chart, etc.)",
            bulletPointAR: "حساب القياسات الأنثروبومترية (مؤشر كتلة الجسم ، وزن الجسم المثالي ، الرسم البياني للنمو ... الخ)",
          },
          {
            bulletPointEN: "Guidance and education of the patient and his family about nutrition is important.",
            bulletPointAR: "إرشاد وتثقيف المريض وعائلته للتغذية التي يحتاجها",
          },
          {
            bulletPointEN: "Prevention of chronic and acute diseases.",
            bulletPointAR: "الوقاية من الأمراض المزمنة /الحادة",
          }
        ]
      },
    ],
  },
  {
    title: 'Postoperative_care',
    iconUrl: '/images/home_2/Postoperative_care.png',
    // href: '/departments/department-details',
    services: [
      {
        serviceTitleEn: 'Postoperative Care Services',
        serviceTitleAr: 'خدمات رعاية ما بعد الجراحة',
        dots: [
          {
            bulletPointEN: "Proper nutrition as appropriate for surgery .",
            bulletPointAR: "نظام غذائي متوازن يتلائم مع العملية",
          },
          {
            bulletPointEN: "Wound care, administration of prescribed medications, and daily wound cleaning and disinfection",
            bulletPointAR: "العناية بالجروح واستخدام الأدوية حسب الوصفة وتعقيم الجروح وتنظيفها يومياً",
          },
          {
            bulletPointEN: "Prevention of postoperative complications.",
            bulletPointAR: "الوقاية من المضاعفات بعد العملية",
          },
          {
            bulletPointEN: "Care and minimization of bruises and swelling.",
            bulletPointAR: "العناية والتقليل من الكدمات والانتفاخات",
          },
          {
            bulletPointEN: "Suture Removal as prescribed by the doctor.",
            bulletPointAR: "إزالة الغرز حسب الطبيب",
          },
          {
            bulletPointEN: "Gives important advice to avoid delaying wound healing.",
            bulletPointAR: "اعطاء النصائح المهمة لتجنب تأخير التئام الجروح",
          },
          {
            bulletPointEN: "Helps with activities and sports in the days or weeks after surgery.",
            bulletPointAR: "المساعدة على القيام بالنشاطات والرياضة بعد العملية بأيام أو أسابيع",
          }
        ]
      },
    ],
  },
];
const testimonialData = [
  {
    text: 'فريق الدعم الفني كان سريع بالرد على استفساراتي وتقديم المساعدة اللي احتجتها. مشكورين على تواصلكم الفعال وسرعة استجابتكم. خليتوا تجربة استخدام الموقع سهلة وسلسة، والتعامل معكم كان مريح وممتع.',
    ratingNumber: '5',
    avatarImgUrl: '/images/user.png',
    avatarName: 'ناصر العقيل',
    avatarDesignation: 'Ryiadh, KSA',
  },
  {
    text: 'جربت خدماتكم لأول مرة وكانت التجربة رهيبة! الفريق الطبي كان محترف بالحيل وتعامل مع الحالة بمهارة واهتمام. مشكورين على الرعاية الممتازة. كانت نصائحهم الطبية واضحة ومفيدة مرة، وحسيت بتحسن كبير بعد أول جلسة.',
    ratingNumber: '4.5',
    avatarImgUrl: '/images/user.png',
    avatarName: 'عبدالله الحربي',
    avatarDesignation: 'Jeddah, USA',
  },
  {
    text: 'حبيت إن الفريق الطبي وصل على الوقت المحدد وكانوا جاهزين تمام. الالتزام بالمواعيد كان شيء مهم بالنسبة لي وارتحت مع فريقكم. هالاحترافية تخليني أثق بخدماتكم وأوصي بها لأي أحد يحتاج رعاية منزلية موثوقة.',
    ratingNumber: '5',
    avatarImgUrl: '/images/user.png',
    avatarName: 'عبدالرحمن العساف',
    avatarDesignation: 'Makkah, KSA',
  },
  {
    text: 'خدمة الصحة المنزلية وفرت لي راحة كبيرة، خاصة في الظروف اللي ما أقدر فيها أروح المستشفى. مشكورين على هالخدمة الرهيبة. أحس بالاطمئنان لأنني أحصل على الرعاية الطبية في بيتي بكل راحة وخصوصية.',
    ratingNumber: '4.5',
    avatarImgUrl: '/images/user.png',
    avatarName: 'Sarah Al-Qahtani',
    avatarDesignation: 'Jeddah, KSA',
  },
];
const workingProcessDataEN = [
  {
    title: 'Browse our services',
    subTitle: 'At this stage, the client browses all our services through the website. They can view details and prices and choose the service that suits their needs.',
    iconUrl: '/images/home_2/wording_process_icon_1.svg',
    number: '01',
  },
  {
    title: 'Contact & make an appointment',
    subTitle: 'After selecting the services, the client contacts the support team to schedule a convenient appointment. They can also add any special notes or additional requests.',
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '02',
  },
  {
    title: 'Visit & provide service',
    subTitle: 'You will meet with one of our healthcare <br />professionals who will conduct a thorough <br />examination and provide a diagnosis or <br />treatment plan.',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '03',
  },
  {
    title: 'Follow-up Care',
    subTitle: 'Our medical team is sent to the clients location at the specified time to provide the required service. We ensure high-quality service and adherence to health standards.',
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '04',
  },
  {
    title: 'Evaluation',
    subTitle: 'After the service is completed, the client can rate the service and provide feedback to improve future service quality. We value customer opinions and continuously work on developing our services.',
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '05',
  },
];

const workingProcessDataAR = [
  {
    title: 'تصفح خدماتنا',
    subTitle: 'في هذه المرحلة يقوم العميل بتصفح جميع خدماتنا عن طريق الموقع. يمكنه الاطلاع على التفاصيل والأسعار واختيار الخدمة التي تناسب احتياجاته.',
    iconUrl: '/images/home_2/wording_process_icon_1.svg',
    number: '01',
  },
  {
    title: 'اختيار الخدمات',
    subTitle: 'يقوم العميل بتحديد الخدمات التي يحتاجها من بين الخدمات المتاحة. يمكنه اختيار أكثر من خدمة وإضافتها إلى سلة الحجز.',
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '02',
  },
  {
    title: 'التواصل وتحديد الموعد',
    subTitle: 'بعد اختيار الخدمات، يتواصل العميل مع فريق الدعم لتحديد الموعد المناسب للزيارة. يمكنه أيضاً إضافة أي ملاحظات خاصة أو طلبات إضافية.',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '03',
  },
  {
    title: 'الزيارة وتقديم الخدمة',
    subTitle: 'يتم إرسال فريقنا الطبي إلى موقع العميل في الوقت المحدد لتقديم الخدمة المطلوبة. نحرص على تقديم خدمة عالية الجودة والالتزام بالمعايير الصحية.',
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '04',
  },
  {
    title: 'التقييم',
    subTitle: 'بعد الانتهاء من تقديم الخدمة، يمكن للعميل تقييم الخدمة وإعطاء ملاحظاته لتحسين جودة الخدمة في المستقبل. نقدر آراء العملاء ونعمل على تطوير خدماتنا باستمرار.',
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '05',
  },
];


// const blogData = [
//   {
//     title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
//     thumbUrl: 'images/home_1/post_1.jpeg',
//     date: 'May 1, 2023',
//   },
//   {
//     title: 'Healthy Eating on a Budget: Tips and Strategies',
//     thumbUrl: 'images/home_1/post_2.jpeg',
//     date: 'May 4, 2023',
//   },
//   {
//     title: 'The Importance of Regular Cancer Screenings and Early Detection',
//     thumbUrl: 'images/home_1/post_3.jpeg',
//     date: 'May 1, 2023',
//   },
// ];


const faqDataEN = [
  {
    title: 'What services does ProHealth offer?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I schedule an appointment with ProHealth?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'Do you accept insurance?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'What should I bring to my appointment?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I request a prescription refill?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
];
const faqDataAR = [
  {
    title: 'كيف أستطيع حجز موعد للاستفادة من خدماتكم؟',
    content: 'يمكنك حجز موعد بكل سهولة عن طريق موقعنا الإلكتروني. قم بتصفح الخدمات المتاحة واختيار الخدمة التي ترغب بها، ثم اضغط على زر "حجز موعد" واتبع التعليمات لتحديد التاريخ والوقت المناسب لك.',
  },
  {
    title: 'ما هي طرق الدفع المتاحة لديكم؟',
    content: 'نحن نقبل الدفع عن طريق البطاقات الائتمانية، والتحويل البنكي، وكذلك الدفع عند الاستلام لبعض الخدمات. يمكنك اختيار الطريقة التي تناسبك عند إتمام عملية الحجز.',
  },
  {
    title: 'هل تتوفر لديكم خدمات طبية طارئة؟',
    content: 'نعم، نحن نقدم خدمات طبية طارئة على مدار الساعة. يمكنك التواصل معنا عبر الخط الساخن للطوارئ وسنقوم بإرسال فريق طبي مؤهل إلى موقعك بأسرع وقت ممكن.',
  },
  {
    title: 'هل تشمل خدماتكم جميع مناطق المملكة؟',
    content: 'نعم، نحن نقدم خدماتنا في مختلف مناطق المملكة. يمكنك التحقق من توفر الخدمة في منطقتك عبر موقعنا الإلكتروني أو بالتواصل مع فريق خدمة العملاء.',
  },
  {
    title: 'هل يمكنني إلغاء الموعد بعد الحجز؟',
    content: 'نعم، يمكنك إلغاء الموعد أو تعديله قبل موعد الخدمة بـ 24 ساعة على الأقل. يمكنك القيام بذلك عن طريق حسابك على الموقع أو بالتواصل مع خدمة العملاء.',
  },
];


// const brandData = [
//   {
//     imgUrl: '/images/brand_1.png',
//     imgAlt: 'Brand',
//   },
//   {
//     imgUrl: '/images/brand_2.png',
//     imgAlt: 'Brand',
//   },
//   {
//     imgUrl: '/images/brand_3.png',
//     imgAlt: 'Brand',
//   },
//   {
//     imgUrl: '/images/brand_4.png',
//     imgAlt: 'Brand',
//   },
//   {
//     imgUrl: '/images/brand_5.png',
//     imgAlt: 'Brand',
//   },
//   {
//     imgUrl: '/images/brand_6.png',
//     imgAlt: 'Brand',
//   },
//   {
//     imgUrl: '/images/brand_7.png',
//     imgAlt: 'Brand',
//   },
// ];


export default function HomeStyle2() {
  pageTitle(`${t('Home')}`);
  const currentLanguageCode = cookies.get('i18next');

  const translatedDepartmentData = departmentData.map(department => ({
    ...department,
    title: t(department.title),
  }));

  return (
    <>
      <HeroStyle2
        title={t('Ekhdomni_Medical_Services_Company')}
        subTitle={t('Hero_Long_Text')}
        bgUrl="/images/saudi_doc_old.png"
        imgUrl="/images/home_2/patents.png"
        videoBtnText={t("See_how_we_work")}
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        btnText={t('App_Name')}
        btnUrl="/"
        funfactList={[
          { number: '4+', title: `${t('Years_of_experience')}` },
          { number: '95%', title: `${t('Patient_satisfaction_rating')}` },
          { number: '5000+', title: `${t('Patients_served_annually')}` },
          { number: '10+', title: `${t('Healthcare_providers_on_staff')}` },
        ]}
      />


      {/* Our Services Section */}
      <Section topMd={85} topLg={125} topXl={125}>
        <DepartmentSectionStyle4
          sectionTitle={t('For_Your_Health')}
          sectionTitleUp={t('OUR_DEPARTMENTS')}
          data={translatedDepartmentData}
        />
      </Section>

      {/* About Us Section*/}
      <Section bottomMd={190} bottomLg={145} bottomXl={105}>
        <AboutSectionStyle3
          titleUp={t('ABOUT_US')}
          title={t('About_Long_Text_Title')}
          subTitle={t('About_Long_Text_Content')}
          imgUrl="/images/saudi_doc_small_img.png"
        />
      </Section>

      {/* Testimonial Section */}
      <Section
        className="cs_bg_filed"
        style={{ backgroundImage: `url(/images/home_2/testimonial_bg.svg)` }}
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSectionStyle2
          sectionTitle={t('What_Our_Patients_Say_About_Us')}
          sectionTitleUp={t('TESTIMONIALS')}
          data={testimonialData}
        />
      </Section>

      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <WorkingProcess
          sectionTitle={t('How_it_Works')}
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={currentLanguageCode === 'en' ? workingProcessDataEN : workingProcessDataAR}
        />
      </Section>

      {/* Banner Section */}
      <Section>
        <BannerSectionStyle2
          bgUrl={ddoc}
          title={t('Dont_Let_Your_Health_Take_a_Backseat')}
          subTitle={t('Schedule_an_appointment')}
        />
      </Section>

      {/* BlogSection */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSectionStyle3
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          sectionTitleDown=""
          sectionSubTitle=""
          data={blogData}
        />
      </Section> */}

      {/* Start FAQ Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <FaqSectionStyle2
          data={currentLanguageCode === 'en' ? faqDataEN : faqDataAR}
          sectionTitle={t("Usually_Asked")}
          sectionTitleUp={t("What_People")}
        />
      </Section>
      {/* End FAQ Section */}


      {/* <Section>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
        />
      </Section> */}

      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} />
      </Section> */}
    </>
  );
}