import React from 'react';
import cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import plus_black from '../../images/plus_black.svg'
// import minus_black from '../../images/minus_black.svg'

export default function IconBoxStyle2({ title, href, iconUrl, services, isOpen, onToggle }) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation();

  return (
    <div className="relative group" onClick={onToggle}>
      <Link to={href} className="block px-4 py-10 bg-white rounded-3xl shadow-md hover:shadow-lg border-[1px] border-[#307BC4] transition-shadow duration-300">
        <div className="flex gap-3 items-center space-x-4">
          <img src={iconUrl} alt="Icon" className="w-12 h-12" />
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
      </Link>
      {/* <div className={`absolute inset-x-0 ${isOpen ? '-bottom-8 md:-bottom-8' : '-bottom-3 md:-bottom-3'}  flex text-3xl justify-center cursor-pointer`} onClick={onToggle}>
        <img className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'text-red-500'}`} src={isOpen ? minus_black : plus_black} />
      </div> */}

      {isOpen && (
        <div className="overflow-x-scroll ps-3 py-2 flex">
          {services.map((service, index) => (
            <div key={index} className='flex-none w-[250px] shadow-md me-4 rounded-[20px]'>
              <div className='flex flex-col items-center'>
                {service.imgUrl && (
                  <img className='w-[250px] h-[187px] rounded-t-[20px]' src={service.imgUrl} alt={service.serviceName} />
                )}
                {currentLanguageCode === 'en' ? (
                  <>
                    <h1 className='font-bold text-[16px] text-center my-4 px-2'>{service.serviceTitleEn}</h1>
                  </>
                ) : (
                  <>
                    <h1 className='font-bold text-[16px] my-4 px-2 text-center'>{service.serviceTitleAr}</h1>
                  </>
                )}

                {service.dots && (
                  <>
                    <ul className='list-disc ps-5 mb-3 text-mainBrown'>
                      {service.dots.map((dot, index) => (
                        <>
                          {currentLanguageCode === 'en' ? (
                            <>
                              <li className='text-[#494949] text-[12px] '>{dot.bulletPointEN}</li>
                            </>
                          ) : (
                            <>
                              <li className='text-[#494949] my-1 text-[12px]'>{dot.bulletPointAR}</li>
                            </>
                          )}
                        </>
                      ))}
                    </ul>
                  </>
                )}

                {service.conditionEn && (
                  <div className='flex flex-col items-start w-full ms-8 mb-3'>
                    <h1 className='text-red-500 text-[12px] font-black'>{t('Condition')}</h1>
                    <h1 className='text-black text-[12px] font-medium italic'>
                      {currentLanguageCode === 'en' ? service.conditionEn : service.conditionAr}
                    </h1>
                  </div>
                )}

                <div className='flex flex-col w-full px-3 mb-3 gap-2 mx-3'>

                  {currentLanguageCode === 'en' ?
                    <button className='w-full'
                      onClick={() => window.open(`https://wa.me/+966506024911?text=${t('I_Want_To_Book_Now')} ${service.serviceTitleEn}`)}>
                      <div className='bg-black py-[5px] px-[12px] rounded-[11px] text-white flex justify-center'>
                        <h1 className='text-[16px] text-white'>{t('Book_Now')}</h1>
                      </div>
                    </button>
                    :
                    <button className='w-full'
                      onClick={() => window.open(`https://wa.me/+966506024911?text=${t('I_Want_To_Book_Now')} ${service.serviceTitleAr}`)}>
                      <div className='bg-black py-[5px] px-[12px] rounded-[11px] text-white flex justify-center'>
                        <h1 className='text-[16px] text-white'>{t('Book_Now')}</h1>
                      </div>
                    </button>
                  }

                  {service.pdfImage && (
                    <button className='w-full'
                      onClick={() => window.open(service.pdfImage)}
                    >
                      <div className='border-[#D8195B] border-2 py-[5px] px-[12px] rounded-[11px] text-black flex justify-center'>
                        <h1 className='text-[16px]'>{t('Package_Details')}</h1>
                        {/* <img className='w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' /> */}
                      </div>
                    </button>
                  )}
                </div>

              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
